import React from 'react';
import Form from './Track/search';

function ManageBookingPage() {
  return (
    <>
   <Form />
   </>
  );
}

export default ManageBookingPage;
