export const SELECT_SERVICE = 'SELECT_SERVICE';
export const ADD_FAMILY = 'ADD_FAMILY';
export const DELETE_FAMILY = 'DELETE_FAMILY';
export const LOGIN_USER_DATA = 'LOGIN_USER_DATA';
export const LOGOUT_USER_DATA = 'LOGOUT_USER_DATA';
export const EDIT_FAMILY = 'EDIT_FAMILY';
export const ADD_APPOINTMENT_DATE = 'ADD_APPOINTMENT_DATE';
export const ADD_REPLACMENT_REASON = 'ADD_REPLACMENT_REASON';
export const ADD_PERSONAL_INFO = 'ADD_PERSONAL_INFO';

export const ADD_ADDRESS_INFO = 'ADD_ADDRESS_INFO';
export const ADD_TRAVEL_PLAN = 'ADD_TRAVEL_PLAN';
export const ADD_GROUP_PERSONAL_INFO = 'ADD_GROUP_PERSONAL_INFO';
export const ADD_GROUP_ADDRESS_INFO = 'ADD_GROUP_ADDRESS_INFO';
export const ADD_EDIT_FAMILY = 'ADD_EDIT_FAMILY';
export const DELETE_EDIT_FAMILY = 'DELETE_EDIT_FAMILY';
export const UPDATE_EDIT_FAMILY = 'UPDATE_EDIT_FAMILY';
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
export const ADD_APPLICATION_LIST = 'ADD_APPLICATION_LIST';
export const ATTACHIMENT = 'ATTACHIMENT';
export const ADD_COMMON_DATA = 'ADD_COMMON_DATA';
export const ADD_APPOINTMENT_TYPE = 'ADD_APPOINTMENT_TYPE';
export const ADD_NEW_REQUEST = 'ADD_NEW_REQUEST';
export const ADD_PAYMENT_OPTIONID = 'ADD_PAYMENT_OPTIONID';
export const SAVE_SITE_INFORMATION = 'SAVE_SITE_INFORMATION';
export const FAMILY_TYPE = 'FAMILY_TYPE';
export const PRICE_INFO = 'PRICE_INFO';
export const ADD_ATTACHMENT_INFO = 'ADD_ATTACHMENT_INFO';
export const DELET_REQUEST ='DELET_REQUEST';
export const DELET_PERSONAL_INFO = 'DELET_PERSONAL_INFO';
export const DELET_ADDRESS_INFO = 'DELET_ADDRESS_INFO';
export const DELET_FAMILY_INFO = 'DELET_FAMILY_INFO';
export const DELETE_TRAVEL_INFO = 'DELET_TRAVEL_INFO';
export const DELETE_APPLICATION_LIST = 'DELET_APPLICATION_LIST';
