import React, { useEffect, useState,useRef, forwardRef, useImperativeHandle } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import { useTranslation, Trans } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";

function requestTypeGetter(requetTypeId) {
  switch (requetTypeId) {
    case 2:
      return 'New';
    case 3:
      return 'Renew/Replacement';
    case 4:
      return 'Lost';
    case 8:
      return 'Correction';
    default:
      return 'Unkown';
  }
}
const SiteSelection = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  const [recaptchaSelected, setRecaptchaSelected] = useState(true);
  const [urgentTypeValue, setUrgentTypeValue] = useState("");
  const [formCompleted, setFormCompleted] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [notCompleted, setNotCompleted] = useState(false);
  const captchaRef = useRef(null)
  const handleRegionChange = (event) => {
    setUrgentTypeValue(event.target.value);
    localStorage.removeItem("UType");
    localStorage.setItem("UType",event.target.value);
    if(event.target.value==="1"||event.target.value==="2")
    {
      setNotCompleted(false)
      setFormCompleted(true);
    }
    else{
      setNotCompleted(true)
      setFormCompleted(false);
    }
  };
  useImperativeHandle(ref, () => ({
    saveData() {
      setDataSaved(true);
    },
    isCompleted() {
      if(urgentTypeValue!=="1"||urgentTypeValue!=="2")
        {
          setNotCompleted(true)
        }else setNotCompleted(false)
    const token = captchaRef.current.getValue();
    if (token === "") {
      setRecaptchaSelected(false);
      return false;
    } else 
      return formCompleted;
    }
}));
  useEffect(() => {
  }, []);

  return (
    <MDBCard style={{ marginBottom: "1rem" }}>
      <MDBCardBody>
        <form>
          <div class='row noShadow'>
            <div class="col-md-4">
              <MDBRow>
                <MDBCol className='mb-3'>
                  <label>
                  <Trans>Urgency</Trans><i style={{ color: 'red' }}>*</i>{' '}
                  </label>
                  <ReactBootstrap.Form.Control
                    onChange={(e)=> handleRegionChange(e)}
                    as="select"
                  >
                    <option value="">Please select the urgency level</option>
                    <option value="1">Urgent (2 Days)</option>
                    <option value="2">Urgent (5 Days)</option>
                  </ReactBootstrap.Form.Control>
                  <span style={{ color: "red" }}> {(notCompleted == true) ? "Please select urgent type" : null}</span>
                </MDBCol >
              </MDBRow>

                             <div className='my-2'>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    ref={captchaRef}
                    />
                      {recaptchaSelected ? null : (
                    <span className="text-danger">
                      Please select the recaptcha!
                    </span>
                  )}
                  </div>
              </div>
            <div class="col-md-8">
              <app-right-content
                class="small-12 medium-4 large-offset-1 large-4 column sticky-container"
                data-sticky-container=""
                _nghost-kxs-c3=""
              >
                <aside
                  class="sidebar small sticky is-anchored is-at-top"
                  data-btm-anchor="request-an-appointment:bottom"
                  data-margin-top="2"
                  data-sticky="s2eunn-sticky"
                  data-sticky-on="medium"
                  data-top-anchor="180"
                  id="raa-sidebar"
                  data-resize="raa-sidebar"
                  data-mutate="raa-sidebar"
                  data-events="mutate"
                >

                  <div class="multistep-form__details sidebar__box sidebar__box--border sidebar__box--teal ng-star-inserted">
                     <h5 className='text-start mx-3'><b>For passport collection under the urgent processing category</b>:</h5>
                    <MDBRow>

                      <fieldset className='my-2 mx-5'>
                     <b>Urgent (2 Days)</b>: Passport collection available within two working days post application.
                    </fieldset>
                    <fieldset className='mx-5'>
                    <b>Urgent (5 Days)</b>: Passport collection available within five working days post application.
                      </fieldset>
                    </MDBRow>
                     </div>

                </aside>
              </app-right-content>
            </div>
          </div>
        </form>

      </MDBCardBody>
      
    </MDBCard>

  );
});
export default SiteSelection
