import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MDBContainer } from 'mdbreact';
import samoleoid from './Images/samoleoid.png'

import '../Help and Support/Faq.css'
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function OriginIdInformation() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (
        <MDBContainer className="container" >
            <div class="row passportaccordion" >
                <div class="col-lg-12 mr-5">
                <div class="u-center-text u-margin-bottom-big mt-5">
                    {/* <h2 class="heading-secondary">  <Trans>howToApply.howtoApply</Trans></h2> */}
                </div>

                    <div className={classes.root} class='faq-accordion'>
                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className='accordion-title'>
                                <Trans>howToApplyoid.newoid</Trans>
                            </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p class='font-weight-bold'>
                                  <Trans>oidrequirments.new.title</Trans>  
                                    </p>

                                    <br></br>
                                    <ol>
                                        <li><Trans>oidrequirments.common.itemOne</Trans></li>
                                        <ul class='my-1'>
                                        <li><Trans>oidrequirments.common.itemTwoL1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL3</Trans></li>
                                        </ul>

                                        <li><Trans>oidrequirments.common.itemTwo</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemTwoL4</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL5</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL6</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL7</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL8</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL9</Trans></li>
                                            <li><Trans>oidrequirments.common.itemTwoL10</Trans></li>
                                        </ul>
                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                                <tr>

                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans></th>
                                                    <th scope="col"><strong>300 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>
                                        <li><Trans>oidrequirments.common.itemFour</Trans></li>
                                        <li><Trans>oidrequirments.common.itemFive</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSix</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSeven</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemSeven1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven3</Trans></li>
                                        </ul>
                                    </ol>

                                </Typography>
                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans></Link></div>
                        </Accordion>
                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className='accordion-title'>
                                <Trans>howToApplyoid.expiredoid</Trans>
      </Typography>
                            </AccordionSummary>


                            <AccordionDetails>
                                <Typography>
                                <h5><strong>Note</strong>: <Trans>oidrequirments.expiredoid.suggestion</Trans> </h5>
                             <img src={samoleoid} alt="Origin ID sample image" style={{maxWidth:"400px"}}/>
                             <br/>
                             <br/>
                                    <p class='font-weight-bold'>
                                    <Trans>oidrequirments.expiredoid.title</Trans>  
                                    </p>

                                    <br/>
                                    <ol>
                                        <li>  <Trans>oidrequirments.common.itemOne</Trans> </li>
                                        <ul class='my-1'>
                                            <li> <Trans>oidrequirments.expiredoid.itemOneL1</Trans></li>
                                            <li> <Trans>oidrequirments.expiredoid.itemOneL2</Trans></li>
                                            <li> <Trans>oidrequirments.expiredoid.itemOneL3</Trans></li>
                                            <li> <Trans>oidrequirments.expiredoid.itemOneL4</Trans></li>

                                        </ul>

     
                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                                <tr>

                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans> &nbsp;for adult</th>
                                                    <th scope="col"><strong>300 USD</strong></th>
                                                </tr>
                                                <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans>&nbsp;for children</th>
                                                    <th scope="col"><strong>100 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>
                                        <li><Trans>oidrequirments.common.itemFour</Trans></li>
                                        <li><Trans>oidrequirments.common.itemFive</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSix</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSeven</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemSeven1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven3</Trans></li>
                                        </ul>
                                    </ol>

                                </Typography>
                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans></Link></div>
                        </Accordion>
                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                            >
                                <Typography className='accordion-title'>
                                <Trans>howToApplyoid.damagedoid</Trans>
          </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p class='font-weight-bold'>
                                    <Trans>oidrequirments.damagedoid.title</Trans>  
                                    </p>

                                    <br></br>
                                    <ol>
                                        <li> <Trans>oidrequirments.common.itemOne</Trans>  </li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.damagedoid.itemOneL1</Trans> </li>

                                        </ul>

           
                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                                <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans> &nbsp;for adult</th>
                                                    <th scope="col"><strong>450 USD</strong></th>
                                                </tr>
                                                <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans>&nbsp;for children</th>
                                                    <th scope="col"><strong>250 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>
                                        <li><Trans>oidrequirments.common.itemFour</Trans></li>
                                        <li><Trans>oidrequirments.common.itemFive</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSix</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSeven</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemSeven1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven3</Trans></li>
                                        </ul>
                                    </ol>

                                </Typography>
                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans></Link></div>
                        </Accordion>
                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className='accordion-title'>
                                <Trans>howToApplyoid.lostoid</Trans>
      </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p class='font-weight-bold'>
                                    <Trans>oidrequirments.lostoid.title</Trans>  
                                </p>

                                    <br></br>
                                    <ol>
                                        <li><Trans>oidrequirments.common.itemOne</Trans>  </li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.lostoid.itemOneL1</Trans>  </li>
                                            <li><Trans>oidrequirments.lostoid.itemOneL2</Trans>  </li>
                                            <li><Trans>oidrequirments.lostoid.itemOneL3</Trans>  </li>
                                            <li><Trans>oidrequirments.lostoid.itemOneL4</Trans>  </li>


                                        </ul>

                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                            <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans> &nbsp;for adult</th>
                                                    <th scope="col"><strong>450 USD</strong></th>
                                                </tr>
                                                <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans>&nbsp;for children</th>
                                                    <th scope="col"><strong>250 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>
                                        <li><Trans>oidrequirments.common.itemFour</Trans></li>
                                        <li><Trans>oidrequirments.common.itemFive</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSix</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSeven</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemSeven1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven3</Trans></li>
                                        </ul>
                                    </ol>

                                </Typography>
                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans></Link></div>
                        </Accordion>
                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography className='accordion-title'>
                                <Trans>howToApplyoid.changeofoidData</Trans>
      </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>

                                    <p class='font-weight-bold'>
                                    <Trans>oidrequirments.changeofoidData.title</Trans>  

                                </p>

                                    <br></br>
                                    <ol>
                                        <li>    <Trans>oidrequirments.common.itemOne</Trans>  </li>
                                        <ul class='my-1'>
                                            <li>        <Trans>oidrequirments.changeofoidData.itemOneL1</Trans>   </li>
                                            <li>        <Trans>oidrequirments.changeofoidData.itemOneL2</Trans>  </li>
                                            <li>        <Trans>oidrequirments.changeofoidData.itemOneL3</Trans>  </li>
                                            <li>        <Trans>oidrequirments.changeofoidData.itemOneL4</Trans>  </li>
                                            <li>        <Trans>oidrequirments.changeofoidData.itemOneL5</Trans>  </li>
                                            <li>        <Trans>oidrequirments.changeofoidData.itemOneL6</Trans>  </li>

                                        </ul>
       
                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                            <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans> &nbsp;for adult</th>
                                                    <th scope="col"><strong>350 USD</strong></th>
                                                </tr>
                                                <tr>
                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans>&nbsp;for children</th>
                                                    <th scope="col"><strong>150 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>
                                        <li><Trans>oidrequirments.common.itemFour</Trans></li>
                                        <li><Trans>oidrequirments.common.itemFive</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSix</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSeven</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemSeven1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven3</Trans></li>
                                        </ul>
                                    </ol>

                                </Typography>
                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans></Link></div>
                        </Accordion>
                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography className='accordion-title'>
                              <Trans>howToApplyoid.oidforspouse</Trans>
      </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <p class='font-weight-bold'>
                                    <Trans>oidrequirments.oidforspouse.title</Trans>  
                                </p>

                                    <br></br>
                                    <ol>
                                        <li> <Trans>oidrequirments.common.itemOne</Trans> </li>
                                        <ul class='my-1'>
                                            <li>  <Trans>oidrequirments.oidforspouse.itemOneL1</Trans>  </li>
                                            <li>  <Trans>oidrequirments.oidforspouse.itemOneL2</Trans>  </li>
                                            <li>  <Trans>oidrequirments.oidforspouse.itemOneL3</Trans>  </li>
                                            <li>  <Trans>oidrequirments.oidforspouse.itemOneL4</Trans>  </li>
                                            <li>  <Trans>oidrequirments.oidforspouse.itemOneL5</Trans>  </li>
                                            <li>  <Trans>oidrequirments.oidforspouse.itemOneL6</Trans>  </li>

                                        </ul>
       
                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                                <tr>

                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans></th>
                                                    <th scope="col"><strong>300 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>
                                        <li><Trans>oidrequirments.common.itemFour</Trans></li>
                                        <li><Trans>oidrequirments.common.itemFive</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSix</Trans></li>
                                        <li><Trans>oidrequirments.common.itemSeven</Trans></li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.common.itemSeven1</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven2</Trans></li>
                                            <li><Trans>oidrequirments.common.itemSeven3</Trans></li>
                                        </ul>
                                    </ol>

                                </Typography>
                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans></Link></div>
                        </Accordion>

                        <Accordion className='accordion-item'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography className='accordion-title'>
                                <Trans>howToApplyoid.applicantUnder18</Trans>
                     </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>

                                    <p class='font-weight-bold'>
                                    <Trans>oidrequirments.applicantUnder18.title</Trans>                                  </p>

                                    <br></br>
                                    <ol>
                                        <li> <Trans>oidrequirments.common.itemOne</Trans>      </li>
                                        <ul class='my-1'>
                                            <li><Trans>oidrequirments.applicantUnder18.itemOneL1</Trans>     </li>
                                            <li><Trans>oidrequirments.applicantUnder18.itemOneL2</Trans>     </li>
                                            <li><Trans>oidrequirments.applicantUnder18.itemOneL3</Trans>     </li>
                                            <li><Trans>oidrequirments.applicantUnder18.itemOneL4</Trans>     </li>
                                            <li><Trans>oidrequirments.applicantUnder18.itemOneL5</Trans>     </li>
                                        </ul>
                                        <li><Trans>oidrequirments.common.itemThree</Trans></li>
                                        <table class="table w-75 my-1">
                                            <thead class="thead-lightt">
                                                <tr>

                                                    <th scope="col"><Trans>oidrequirments.common.price</Trans></th>
                                                    <th scope="col"><strong>100 USD</strong></th>
                                                </tr>
                                            </thead>
         
                                        </table>


                                    </ol>

                                </Typography>

                            </AccordionDetails>
                            <div class="col-12 medium text-center my-3"><Link to="/request-oid-appointment" class="btn btnBlu"><Trans>requirments.common.applynow</Trans> </Link></div>

                        </Accordion>
                      
                    </div>
                </div>

            </div>



        </MDBContainer>
    );
}
