import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MDBContainer } from 'mdbreact';
import '../Help and Support/Faq.css'
import { Link } from 'react-router-dom';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import OriginIdInformation from '../OID/OriginIdInformation';
import PassportInformation from './PassportInformation';
import UrgentPassportInformation from './UrgentPassportInformation';


import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function Information() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (

<div className='container'>
<br/>
<Tabs
  defaultActiveKey="home"
  transition={false}
  id="noanim-tab-example"
  className="mb-3"
>
  <Tab eventKey="home" title={ <Trans>How To Apply for Regular Passport</Trans>}>
    <PassportInformation />
  </Tab>
  <Tab eventKey="profile" title={ <Trans>How To Apply for Urgent Passport</Trans>}>
    <UrgentPassportInformation />
  </Tab>

</Tabs>

</div>

    );
}
